import React, { useState, useEffect } from 'react';
import '../../../assets/styles/components/pages/Wallet/TripsMade.scss'; 
import axios from 'axios';
import { API_BASE_URL  } from  '../../../utils/apiConfig'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import { FaTruckLoading } from 'react-icons/fa';
import useFormatTime from '../../../utils/useFormatTime';
import { BsTelephoneFill, BsPersonBadgeFill } from 'react-icons/bs';
import { LiaIdCard } from 'react-icons/lia';
import { CiBoxes } from 'react-icons/ci';
import { FcMoneyTransfer } from 'react-icons/fc';


const TripsMade = () => {
  const [tripsFinished, setTripsMade] = useState([]);
  const [date, setDate] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [totalValue, setTotalValue] = useState(null);
  const [percentage, setPercentage] = useState(0); 
  const [driver, setDriver] = useState('');
  const [token] = useState(localStorage.getItem('authToken'));
  const { formatDateTime } = useFormatTime();

  useEffect(() => {
    getDate()
  }, []);

  const getDate = (specificDate) => {
    if (specificDate) {
      setDate(specificDate);
      fetchTripsMade(specificDate)
    } else {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
  
      const threshold = 14;
      let defaultDate;
      if (day <= threshold) {
        defaultDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      } else {
        defaultDate = `${year}-${month.toString().padStart(2, '0')}-15`;
      }
      setDate(defaultDate);
      fetchTripsMade(defaultDate)
    }
  };

  const fetchTripsMade = async (newDate) => {
    try {
      const response = await axios.get(`${API_BASE_URL}freights/finished/${newDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const tripsFinished = response.data;
      setTripsMade(tripsFinished);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const getInfoUser = (userInfo) => { 
    return (
      <small>
        <ul className="list-group list-group-flush">
          <li className="list-group-item list-group-item-success"><BsPersonBadgeFill /> {`${userInfo.first_name} ${userInfo.last_name}`}</li>
          <li className="list-group-item list-group-item-success"><LiaIdCard /> {userInfo.citizenship_card}</li>
          <li className="list-group-item list-group-item-success">
            <a className='col-12 mt-3' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a>
          </li>
        </ul>
      </small>
    );
  }

  const handleFetchTotal = async (driverId, selectedDate) => {
    try {
      const response = await axios.post(`${API_BASE_URL}freights/finished-driver-id`, {
        driver_id: driverId,
        date: selectedDate,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setTotalValue(response.data.total);
    } catch (error) {
      console.error('Error fetching total value:', error);
    }
  };
  

  const printTripsFinished = () => {
    const tripsToShow = filteredDrivers;
    return (
      <div className='container'>
          <div className='list-transactions mt-3'>
            {
              !(filteredDrivers.length) > 0 ? 
                <div className="alert alert-warning" role="alert">
                  Usuario no encontrado
                </div> 
              : null
            }
            <h1><FaTruckLoading/> Fletes entregados</h1>
            {
              tripsToShow?.map((freight) => (
                <ul className="list-group mt-3" key={freight.id}>
                    <li className='list-group-item d-flex justify-content-between align-items-center list-group-item-success'>
                      {getInfoUser (freight.against_freights_offers[0].user)}
                      
                      <small>
                        <span className="badge bg-light rounded-pill">
                          $ {freight.against_freights_offers[0].price?.toLocaleString('es')} <br/>
                          {formatDateTime(freight.created_at)} 
                        </span> <br/>
                        <FcMoneyTransfer className='icons-details ms-3 mt-3'
                          onClick={
                            () => {
                              handleFetchTotal(freight.against_freights_offers[0].user.id, date)
                              setDriver(freight.against_freights_offers[0].user)
                            }
                            
                          }
                        />
                        <Link to={`/freights/show/${freight.id}`}>
                          <CiBoxes className='icons-details ms-3 mt-3' />
                        </Link>
                      </small>
                    </li>
                </ul>
              ))
            }
          </div>
      </div>
    )
  }

  

  const handleFilterChange = (event) => {
    const { value } = event.target;
    setFilterText(value);
    filterDrivers(value);
  };

  const filterDrivers = (text) => {
    const filtered = tripsFinished.filter((freight) => {
      const driverInfo = freight.against_freights_offers[0].user;
      return driverInfo.citizenship_card.includes(text);
    });
    setFilteredDrivers(filtered);
  };

  useEffect(() => {
    filterDrivers(filterText);
  }, [tripsFinished, filterText]);


  return (
    <div className='mt-3 trips-made row'>
      {
        !driver ?
          <div className="alert alert-info" role="alert">
            <strong>Para generar la liquidación de prestaciones sociales de un conductor</strong> <br />
            1) Seleccione una fecha a partir de la cual desea que se realice la liquidación de beneficios sociales <br />
            2) filtrar por cédula de identidad y seleccionar el conductor para realizar la liquidación <br />
            3) Eso es todo
          </div> 
        : null
      }
      <div className="form-group col-md-3 col-sm-12">
        <h6 className="mt-3" htmlFor="date_load">Traer fletes desde: {date}</h6>
        <input
          type="date"
          className="form-control"
          name="date_load"
          value={date}
          onChange={(e) => {
            getDate(e.target.value)
            // setFilterText('')
          }}
        />
      </div>
      <div className="form-group col-md-3 col-sm-12">
        <h6 className="mt-3" htmlFor="citizenship_card">Buscar por Cédula</h6>
        <input
          type="text"
          className="form-control"
          name="citizenship_card"
          value={filterText}
          onChange={handleFilterChange}
        />
      </div>
      {printTripsFinished()}
      <div className='row justify-content-md-center'>
        {
          driver ? 
          <>
            <h3 className='mt-5'>
              Liquidación de prestaciones sociales desde {date} del conductor {`${driver.first_name} ${driver.last_name}`}  
            </h3>
            <span className='col-md-3 col-sm-12'>
              Liquidación generada desde {date}
              {getInfoUser(driver)}
              <div className="alert alert-primary" role="alert">
                Total a pagar al conductor
                $ {totalValue?.toLocaleString('es')}
              </div>
            </span>
            
            <div className="form-group col-md-3 col-sm-12">
              <h6 className="mt-3" htmlFor="citizenship_card">Agregar el {percentage}% de mi empresa</h6>
              <input
                type="number"
                className="form-control"
                name="percentage"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
              />
              <ul className="list-group list-group-flush ">
                <li className="list-group-item list-group-item-info">
                  ${percentage}% = $ {(totalValue * (percentage / 100))?.toLocaleString('es')} <br />
                </li>
                <li className="list-group-item list-group-item-success">
                  Porcentaje sumado= $ {((totalValue * (percentage / 100)) + totalValue)?.toLocaleString('es')} <br />
                </li>
                <li className="list-group-item list-group-item-danger">
                  Porcentaje restado = $ {(totalValue - (totalValue * (percentage / 100)))?.toLocaleString('es')} <br />
                </li>
              </ul>
            </div>
          </>
          : null
        }
      </div>
    </div>
  );
};

export default TripsMade;
