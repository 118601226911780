import React, { useState, useEffect } from 'react';
import '../../assets/styles/layout/Header.scss';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import { LiaIdCard } from 'react-icons/lia';
import { CiBoxes } from 'react-icons/ci';
import { LiaPiggyBankSolid } from 'react-icons/lia';
import { BiLogOut } from 'react-icons/bi';
import { TbClipboardList } from 'react-icons/tb';
import { PiChatsBold } from 'react-icons/pi';
import { BsPeople, BsCardChecklist } from 'react-icons/bs';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import useCustomFunctions from '../../utils/useCustomFunctions';
import LogoOne from '../../assets/multimedia/LogoOne.jpeg'; 

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

const Header = () => {
  const navigate = useNavigate();
  const [roles] = useState(localStorage.getItem('roles'));
  const { handleAddFreight } = useCustomFunctions();
  const [authUserId] = useState(localStorage.getItem('userId'));

  const handleLogout = () => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      fetch(`${API_BASE_URL}/tokens/current`, {
        method: 'DELETE', // Or the appropriate method to log out in your API
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      })
      .then(response => {
        // Handle the API response here, for example, redirect to the public home page
        localStorage.removeItem('authToken');
        
         // Redirect the user to the public home page
         window.location.href = "/";
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
    }
  };

  const handleCloseAllSessions = () => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      fetch(`${API_BASE_URL}tokens/close-all-sessions`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      })
      .then(response => {
        // Handle the API response here if needed
        localStorage.removeItem('authToken');
        
        // Redirect the user to the public home page
        window.location.href = "/";
      })
      .catch(error => {
        console.error('Error closing all sessions:', error);
      });
    }
  };

  const routesAuthorizedAccordingRole = () => {
    if (roles === 'admin') {
      return (
        <>
          <Link to="/freights" onClick={closeMobileMenu}>
            <CiBoxes className='icons-details' />
            Fletes
          </Link>
          <Link to="/freights/records" onClick={closeMobileMenu}>
            <TbClipboardList className='icons-details' />
            Seguimiento de carga
          </Link>
          <Link to="/my-offers" onClick={closeMobileMenu}>
            <BsCardChecklist className='icons-details' />
            Mis ofertas
          </Link>
          <Link to="/users/control-panel" onClick={closeMobileMenu}>
            <BsPeople className='icons-details' />
            Users
          </Link>
        </>
      );
    } else if (roles === 'driver') {
      return (
        <>
          <Link to="/freights" onClick={closeMobileMenu}>
            <CiBoxes className='icons-details' />
            Fletes
          </Link>
          <Link to="/my-offers" onClick={closeMobileMenu}>
            <BsCardChecklist className='icons-details' />
            Mis ofertas
          </Link>
        </>
      );
    } else if (roles === 'client') {
      return (
        <>
          <Link to="/freights/records" onClick={closeMobileMenu}>
            <TbClipboardList className='icons-details' />
            Seguimiento de carga
          </Link>
        </>
      );
    }
  }

  const closeMobileMenu = () => {
    const navCheck = document.getElementById('nav-check');
    if (navCheck.checked) {
      navCheck.checked = false;
    }
  };

  const handleBack = () => {
    // Navigate to the previous page
    navigate(-1);
  };

  const handleNext = () => {
    // Navigate to the next page
    navigate(1);
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '0753623e3427622a8bf9',
      cluster: 'us2', 
      forceTLS: true,
      // auth: {
      //   headers: {
      //     Authorization: `Bearer ${token}`, 
      //   },
      // },
    });

    echo.channel('freight-channel')
    .listen('FreightEvent', (response) => {
      if (roles !== 'client') {
        handleAddFreight(response.freight)
      }
    });

    return () => {
      setTimeout(() => {
        echo.disconnect();
      }, 500);
    };
  }, []);

  return (
      <header className="header">
        <div className="nav">
          <input type="checkbox" id="nav-check" />
          <div className="nav-header">
            <div className="nav-title col-md-12 col-sm-2">
              <div className='col-md-12 col-sm-2'>
                <img src={LogoOne} alt="logo-ejecarga"/>
              </div>
            </div>
          </div>
          <div className="nav-btn">
            <label htmlFor="nav-check">
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          <div className="nav-links">
            {
              routesAuthorizedAccordingRole()
            }
            <Link to="/chats" onClick={closeMobileMenu}>
              <PiChatsBold className='icons-details' />
              Chats
            </Link>
            <Link to="/wallet" onClick={closeMobileMenu}>
            <LiaPiggyBankSolid className='icons-details' />
              Billetera
            </Link>
            <Link to={`/profile/${authUserId}`} onClick={closeMobileMenu}>
              <LiaIdCard className='icons-details' />
              Perfil
            </Link>
            <Link to="/" data-bs-toggle="dropdown">
              <BiLogOut className='icons-details' />
              Cerrar sesión
            </Link>
            <div className="dropdown">
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#" onClick={handleLogout}>Cerrar</a></li>
                {/* <li><a className="dropdown-item" href="#" onClick={handleCloseAllSessions}>CloseAllSessions</a></li> */}
              </ul>
            </div>
          </div>
      </div>
      <MdNavigateBefore className='icons-details' onClick={handleBack} />
      <MdNavigateNext className='icons-details' onClick={handleNext} />
    </header>
  );
};

export default Header;
