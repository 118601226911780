import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useCustomFunctions from '../../utils/useCustomFunctions';

import '../../assets/styles/components/pages/Freights.scss'; 
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, STORAGE_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import { BsTelephoneFill } from 'react-icons/bs';
import { PiChatsBold } from 'react-icons/pi';
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

const OffersRecords = () => {
  const [offersAccepted, setOffersAccepted] = useState([]);
  const [complitedTrips, setComplitedTrips] = useState([]);
  const [token] = useState(localStorage.getItem('authToken'));
  const [authUserId] = useState(parseInt(localStorage.getItem('userId')));
  const { showFreight } = useCustomFunctions();

  const navigate = useNavigate();

  useEffect(() => {
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      const complitedTripsResponse = await axios.get(`${API_BASE_URL}against-freights-offers/trips-made`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setComplitedTrips(complitedTripsResponse.data);
      const responseOffersAccepted = await axios.get(`${API_BASE_URL}against-freights-offers/offers-accepted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOffersAccepted(responseOffersAccepted.data);
    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  const fetchChatId = async (clientId) => {
    try {
      const responseChat = await axios.get(`${API_BASE_URL}chats/with/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
      });

      const chatId = responseChat?.data?.conversation?.id;
      navigate(`/chats/${chatId}`);
    } catch (error) {
      console.error('Error fetching freight:', error);
    }
  }

  const printOffers = (offers) => {
    return (
      offers ?
      offers.map((offer) => (
         <div key={offer.id}>
          {
            <>
             {
              offer?.freight?.finished ?
                null
              :
                <span className="badge bg-light mt-5">
                  <a className='call' href={`tel:${offer?.freight?.user?.contact_number}`}>
                    <BsTelephoneFill /> {offer?.freight?.user?.contact_number}
                  </a>
                  <button type="button" className="ms-4 btn btn-light chat-with" onClick={() => fetchChatId(offer?.freight?.user_id)}>
                    <PiChatsBold/> Chatear con la cliente
                  </button>
                </span>
            }
            { 
              showFreight(offer?.freight)
            }
            </>
          }
          {
            <ol className="list-group mt-3" key={offer.id}>
            <li className="mt-3 list-group-item d-flex justify-content-between align-items-start">
              <div className="me-auto row">
                <div className='profile-picture col-1'>
                  <Link to={`/profile/${offer.user_id}`} className="banner mt-2 ms-3">
                    <img viewBox="0 0 100 100" src={ `${STORAGE_BASE_URL}${offer?.user?.profile_picture}` } alt={offer?.user?.last_name} />
                  </Link>
                </div>
                <div className="fw-bold col-6 ms-4">
                  {
                    `${offer?.user?.first_name} 
                    ${offer?.user?.last_name}
                    ${offer?.user?.company ? offer?.user?.company : ''}`
                  }
                </div>
                <div className='ms-5'>
                  $ {offer.price}
                </div>
              </div>
            </li>
          </ol>
          }
         </div>
      
      ))
    : <h1>Llama al <a className='call' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a> para ser un conductor verificado y acceder a los fletes ofertados</h1>
    );
  }

  const alertNotification = () => {
    toast.success('You have a new offer accepted', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '0753623e3427622a8bf9',
      cluster: 'us2', 
      forceTLS: true,
      // auth: {
      //   headers: {
      //     Authorization: `Bearer ${token}`, 
      //   },
      // },
    });

    echo.channel(`offers-channel`)
    .listen('OfferUpdated', (response) => {
      const userId = response.offer.user_id;
      if(authUserId === userId) {
        alertNotification()
        fetchOffers();
      }
    });

    return () => {
      echo.disconnect();
      setTimeout(() => {
      }, 500);
    };
  }, []);

  return (
    <div className='container mt-3'>
      <ToastContainer />
        <Tabs className="mt-3">
        <TabList>
          <Tab>Ofertas aceptadas</Tab>
          <Tab>Viajes completados</Tab>
        </TabList>

        <TabPanel>
          {printOffers(offersAccepted)}
        </TabPanel>
        <TabPanel>
          {printOffers(complitedTrips)}
        </TabPanel>
      </Tabs>
  </div>
  );
};

export default OffersRecords;
