import React, { useState } from 'react';
import axios from 'axios';
import '../../assets/styles/containers/App/Login.scss';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import Register from './Register'
import { ToastContainer, toast } from 'react-toastify';
import LogoOne from '../../assets/multimedia/LogoOne.jpeg'; 
import { Link } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
    

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}login`, {
        email,
        password,
      });

      // Assuming the backend returns a token upon successful login
      const token = response.data.api_token;
      const userId = response.data.user_id;
      const roles = response.data.roles;

      // Store the token in local storage
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('roles', roles);

      // Redirect the user to the private home page after successful login
      window.location.href = "/";
    } catch (error) {
      // Handle login error here (e.g., display error message)
      console.error('Login error:', error);
      toast.error('Wrong password or email');
    }
  };

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className='login align'>
        <div className="grid align__item">
          <Link to="/">
            <AiFillHome /> ir a la página principal
          </Link>
          <div className="register">
            <img src={LogoOne} className="site__logo" />

            <h2 className="thick-letter text-color">Bienvenido</h2>

            <form onSubmit={handleLogin} className="row form" method="post">
              <div className="col">
                <input className="email" type="email" placeholder="Correo" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="col">
                <input className="password" type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </div>
              <div className="col">
                <input className="button thick-letter" type="submit" value="Login" />
              </div>
            </form>
            <div className="row justify-content-md-center mt-3 register-section">
              <div className="col-md-auto">
                <button type="button" className="btn btn-success mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Crear una cuenta nueva
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Register />
    </section>
  );
};

export default Login;
