import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import MainLayout from './components/layout/MainLayout';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  // Check if the user is authenticated on initial load (You should implement your authentication logic here)
  React.useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (
        authToken && 
        authToken !== undefined && 
        authToken !== 'undefined'
        ) {
           setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
        }
  }, [isAuthenticated]);

  return (
    
      isAuthenticated ? 
          <Router>
            <MainLayout>
              <PrivateRoute />
            </MainLayout>
          </Router>
        : 
          <Router>
            <PublicRoute />
          </Router>

  );
};

export default App;
