import React, { useState, useEffect } from 'react';
import '../../../assets/styles/components/pages/Wallet/TripsMade.scss'; 
import axios from 'axios';
import { API_BASE_URL  } from  '../../../utils/apiConfig'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import { GiMoneyStack } from 'react-icons/gi';
import useFormatTime from '../../../utils/useFormatTime';

const TripsMade = () => {
  const [tripsFinished, setTripsMade] = useState([]);
  const [token] = useState(localStorage.getItem('authToken'));
  const { formatDateTime } = useFormatTime();

  useEffect(() => {
    fetchTripsMade()
  }, []);

  const fetchTripsMade = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}against-freights-offers/trips-made`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const tripsFinished = response.data;
      setTripsMade(tripsFinished);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const printTripsFinished = () => {
    return (
      <div className='list-transactions'>
        {
          tripsFinished?.map((tripFinished) => (
            <ul className="list-group" key={tripFinished.id}>
              <Link to={`/freights/show/${tripFinished.freight_id}`}>
              <li className={`list-group-item d-flex justify-content-between align-items-center list-group-item-success`}>
                $ {tripFinished.price}
                <small>
                  <span className="badge bg-light rounded-pill">{formatDateTime(tripFinished.created_at)}</span>
                </small>
              </li>
              </Link>
            </ul>
          ))
        }
      </div>
    )
  }

  return (
    <div className='mt-3 trips-made'>
      <h1><GiMoneyStack/> Viajes realizados</h1>
      {
        printTripsFinished()
      }
    </div>
  );
};

export default TripsMade;
