// components/layout/MainLayout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const MainLayout = ({ children }) => {
  return (
    <div>
      <Header>Header</Header>
      <main>{children}</main>
      {/* <Footer>Footer</Footer> */}
    </div>
  );
};

export default MainLayout;
