// chat.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/containers/Chats/Chats.scss';
import useChat from './useChats';

const Chat = () => {
  const {
    participantsConversations,
    conversations,
  } = useChat();



  return (
    <section className='container text-center chats'>
      <div className="row justify-content-md-center">
        <div className="alert alert-info" role="alert">
          Aquí están los chats de los fletes que están en tránsito.
        </div>
        <div className="col-md-auto">
            {
              participantsConversations.map((conversation) => (
                <Link to={`/chats/${conversation.id}`} key={conversation.id}>
                  {
                   conversations(conversation)
                  }
                </Link>
              ))
            }
          </div>
      </div>
    </section>
  );
};

export default Chat;
