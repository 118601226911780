// apiConfig.js
// Localhost
// const API_BASE_URL = 'http://127.0.0.1:8000/api/';
// const STORAGE_BASE_URL = 'http://127.0.0.1:8000/storage/';

// Production
const API_BASE_URL = 'https://api.ejecarga.com/api/';
const STORAGE_BASE_URL = 'https://api.ejecarga.com/storage/';

export { API_BASE_URL, STORAGE_BASE_URL };
