import React, { useState, useEffect } from 'react';
import '../../../assets/styles/components/pages/Wallet/Transactions.scss'; 
import axios from 'axios';
import { API_BASE_URL  } from  '../../../utils/apiConfig'; // Adjust the path as needed
import { GrTransaction } from 'react-icons/gr';
import useFormatTime from '../../../utils/useFormatTime';


const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [token] = useState(localStorage.getItem('authToken'));
  const { formatDateTime } = useFormatTime();

  useEffect(() => {
    fetchTransactions()
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}users/show-transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transactions = response.data.transactions;
      setTransactions(transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const printTransactions = () => {
    const getBackground = (amount) => {
      let backgroundColor = ''
      amount < 0 ? 
        backgroundColor = 'list-group-item-danger' 
        :
        backgroundColor = 'list-group-item-success'
      return backgroundColor;
    }

    return (
      <div className='list-transactions'>
        {
          transactions?.map((transaction) => (
            <ul className="list-group" key={transaction.id}>
              <li className={`list-group-item d-flex justify-content-between align-items-center ${getBackground(transaction.amount)}`}>
                $ {transaction.amount}
                <small>
                  <span className="badge bg-light rounded-pill">{formatDateTime(transaction.created_at)}</span>
                </small>
              </li>
            </ul>
          ))
        }
      </div>
    )
  }

  return (
    <div className='mt-3 transactions'>
      <h1><GrTransaction/> Transacciones</h1>
      {
        printTransactions()
      }
    </div>
  );
};

export default Transactions;
