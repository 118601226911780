import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Login from '../../containers/App/Login';
import PublicHome from '../pages/PublicHome';
import TermsConditions from '../pages/TermsConditions';


const PublicRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} replace />
      <Route path="/" element={<PublicHome />} />
      <Route path="/legal" element={<TermsConditions />} />
      <Route path="/login" element={<Login />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
};

export default PublicRoute;
