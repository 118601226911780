import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateHome from '../pages/PrivateHome';
import About from '../pages/About';
import Contact from '../pages/Contact';
import NotFound from '../pages/NotFound';
import Freights from '../pages/Freights';
import MyOffers from '../pages/MyOffers';
import Profile from '../../containers/Profile/Profile';
import FreightsRecords from '../pages/FreightsRecords';
import ShowFreight from '../pages/ShowFreight';
import Chat from '../../containers/Chats/Chat/Chat.jsx';
import Chats from '../../containers/Chats/Chats.jsx';
import UsersControlPanel from '../../containers/Users/UsersControlPanel';
import Wallet from '../../containers/Wallet/Wallet';
import MyWallet from '../../containers/Wallet/MyWallet';

const PrivateRoute = () => {
  const [roles] = useState(localStorage.getItem('roles'));

  const routesAuthorizedAccordingRole = () => {
    if (roles === 'admin') {
      return (
        <>
          <Route path="/freights" element={<Freights />} />
          <Route path="/freights/show/:freightId" element={<ShowFreight />} />
          <Route path="/freights/records" element={<FreightsRecords />} />
          
          <Route path="/users/control-panel" element={<UsersControlPanel />} />
          <Route path="/users/funds/:userId" element={<Wallet />} />
          
          <Route path="/my-offers" element={<MyOffers />} />
        </>
      );
    } else if (roles === 'driver') {
      return (
        <>
          <Route path="/freights" element={<Freights />} />
          <Route path="/freights/show/:freightId" element={<ShowFreight />} />
          
          <Route path="/my-offers" element={<MyOffers />} />
        </>
      );
    } else if (roles === 'client') {
      return (
        <>
          <Route path="/freights/show/:freightId" element={<ShowFreight />} />
          <Route path="/freights/records" element={<FreightsRecords />} />
        </>
      );
    }
  }

  return (
    <Routes>
      {/* <Route path="*" element={<Navigate to="/" />} replace /> */}
      <Route path="/" element={<PrivateHome />} />
      <Route path="/404" element={<NotFound />} />
      
      <Route path="/wallet" element={<MyWallet />} />

      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />

      <Route path="/chats/" element={<Chats />} />
      <Route path="/chats/:userId" element={<Chat />} />

      <Route path="/profile/:userId" element={<Profile />} />

      {
            routesAuthorizedAccordingRole()
      }
  
      {/* <Route path="/profile" component={Profile} />
      <Route path="/freights/records" component={FreightRecords} />
      <Route path="/budget" component={Budget} /> */}
    </Routes>
  );
};

export default PrivateRoute;
