// useChat.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from  '../../utils/apiConfig'; // Adjust the path as needed
import { BsBuildings } from 'react-icons/bs';

const useChat = () => {
  const [token] = useState(localStorage.getItem('authToken'));
  const [participantsConversations, setParticipantsConversations] = useState([]);
  const [authUserId] = useState(parseInt(localStorage.getItem('userId')));

   useEffect(() => {
    const fetchAllConversations = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}chats/all-conversations/${authUserId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setParticipantsConversations(response.data?.conversations)
      } catch (error) {
        console.error('Error getting participants:', error);
      }
    };

    fetchAllConversations();
  }, []);

  const conversations = (conversations) => {
    let participant = [];
    if (conversations?.participant1) {
      participant = conversations?.participant1;
    } else if (conversations?.participant2) {
      participant = conversations?.participant2;
    }
    return (
      <ol className="list-group mt-3">
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div className="me-auto row">
            <div className='profile-picture-chats col-1'>
                <div className="banner ms-3">
                  {
                    participant.profile_picture ?
                      <img viewBox="0 0 100 100" src={ `${STORAGE_BASE_URL}${participant?.profile_picture}` } alt={participant?.last_name} />
                    : null
                  }
                </div>
            </div>
            <div className="fw-bold col ms-2">
              {`${participant?.first_name} ${participant?.last_name}`} <br />
              <BsBuildings /> { participant?.company }
            </div>
          </div>
        </li>
      </ol>
    )
  }


  return {
    participantsConversations,
    conversations,
  };
};

export default useChat;
