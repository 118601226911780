import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import '../../assets/styles/containers/App/Register.scss';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Register = () => {
  const [citizenshipCard, setCitizenshipCard] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('driver');
  const isPasswordValid = password.length >= 8;

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}register`, {
        citizenship_card: citizenshipCard,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        contact_number: contactNumber,
        company,
        role,
      });

      // Assuming the backend returns a token upon successful login
      const token = response.data.api_token;
      const userId = response.data.user_id;
      const roles = response.data.roles;

      // Store the token in local storage
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('roles', roles);

      window.location.href = "/";
    } catch (error) {
      // Handle registration error here (e.g., display error message)
      console.error('Registration error:', error);
      toast.error('El correo electrónico o la cédula de ciudadanía ya existe');
    }
  };

  return (
    <div className='register'>
      <ToastContainer />
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Registrarse</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form className='row' onSubmit={handleRegister}>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Nombres" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Apellidos" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="text" value={citizenshipCard} onChange={(e) => setCitizenshipCard(e.target.value)} placeholder="Cédula de ciudadanía" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder="Número de celular" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <input className='form-control' type="text" value={company} onChange={(e) => setCompany(e.target.value)} placeholder="Empresa (opcional)" />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <label><small>Contraseña (8 caracteres o más)</small></label>
                  <input className='form-control' type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" required />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                  <label>Tipo de usuario</label>
                  <select className='form-select' value={role} onChange={(e) => setRole(e.target.value)} required>
                      <option value="driver">Conductor</option>
                      <option value="client">Cliente</option>
                  </select>
                </div>
                <p className='mt-3'>
                  <small>
                    Al hacer clic en "Registrarte", aceptas nuestros  
                    <button className='btn btn-sm' data-bs-dismiss="modal">
                      <Link to="/legal"> Términos, condiciones, </Link> 
                    </button> <br/>
                    la Política de privacidad y la Política de cookies. Es posible que te enviemos notificaciones por SMS, que puedes desactivar cuando quieras.
                  </small>
                </p>
                
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                  <button 
                    type="submit" 
                    className="btn btn-success"
                    disabled={!isPasswordValid || // Disable if password is less than 8 characters
                    !(
                      company !== 'EjeCarga' &&
                      company !== 'ejecarga' &&
                      company !== 'Ejecarga' &&
                      company !== 'ejeCarga' &&
                      company !== 'Eje Carga' &&
                      company !== 'eje carga' &&
                      company !== 'Eje carga' &&
                      company !== 'eje Carga'
                    )}>
                    Registrarte</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
