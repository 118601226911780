const useFormatTime = () => {
 
  const formatTime = (timeString) => {
    if (timeString) {
      const [hours, minutes] = timeString.split(':');
      const formattedTime = new Date(0, 0, 0, hours, minutes).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
      return formattedTime;
    }
  };

  const formatDateTime = (dateTimeString) => {
    const transactionDate = new Date(dateTimeString);
    return transactionDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  return {
    formatTime,
    formatDateTime,
  };
};

export default useFormatTime;
