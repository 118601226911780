import React from 'react';
import { Link } from 'react-router-dom';

const TermsConditions = () => {
  return (
    <section className="">
      <div className="card" >
        <div className="card-body">
          <h5 className="card-title">Términos y condiciones</h5>
          <p className="card-text">
            Términos de servicio, estructura de precios y detalles de comisiones para conductores

            1. Términos de Servicio: Al utilizar la plataforma EjeCarga, usted acepta los siguientes términos de servicio:

            EjeCarga sirve como plataforma para conectar a clientes y conductores de servicios de transporte. No somos responsables de los resultados o incidentes relacionados con los materiales transportados, y cada cliente y conductor asume total responsabilidad por sus respectivas acciones y resultados.

            EjeCarga no es responsable de ningún daño, pérdida, lesión o disputa legal que surja del transporte de materiales o cualquier actividad relacionada. La plataforma actúa únicamente como intermediario para facilitar las conexiones entre los usuarios.

            Los usuarios deben cumplir con todas las leyes, regulaciones y estándares de seguridad locales mientras usan la plataforma. Cualquier infracción puede dar lugar a la suspensión o cancelación de la cuenta.

            Los bonos proporcionados a los usuarios no son transferibles, no intercambiables y no pueden canjearse por dinero en efectivo. Están destinados a un uso exclusivo dentro de la plataforma.

            Los usuarios deben proporcionar información precisa y actualizada durante el registro y uso de la plataforma.

            2. Estructura de precios:

            Los clientes pueden publicar fletas (tareas de carga) en la plataforma pagando una tarifa. El precio por publicar una flecha varía según factores como la distancia, el tipo de material y la urgencia. Los detalles exactos de los precios se pueden encontrar dentro de la plataforma durante el proceso de creación de archivos.

            Los conductores pueden ver y ofertar por flotas disponibles sin ningún cargo. Sin embargo, para realizar una puja u oferta, los conductores deben mantener un saldo de cuenta positivo.

            3. Detalles de la comisión para conductores:

            Los conductores están sujetos a una comisión del 5% del monto de la oferta o de la oferta si el cliente acepta su oferta. Esta comisión cubre el uso de la plataforma y sus servicios.

            La comisión se deduce automáticamente del saldo de la cuenta del conductor cuando el cliente acepta la oferta.

            Es responsabilidad del conductor asegurarse de tener fondos suficientes en su cuenta para cubrir la comisión.

            4. Limitación de Responsabilidad:

            EjeCarga y sus creadores no serán responsables de ningún evento, daño, pérdida, lesión, disputa o asunto legal relacionado con el transporte, uso o resultados de las flechas o los servicios de la plataforma.

            La plataforma opera como intermediario que conecta a los usuarios para los servicios de transporte. Los usuarios son responsables de sus propias acciones y decisiones.

            La plataforma no garantiza la exactitud, seguridad o calidad de los materiales transportados o los servicios prestados por los conductores.

            5. Bonos y Créditos:

            Los bonos y créditos proporcionados a los usuarios están destinados a ser utilizados exclusivamente dentro de la plataforma EjeCarga. No se pueden convertir en efectivo ni transferir a otros métodos de pago.

            Los usuarios no pueden intercambiar bonos o créditos por ningún bien o servicio externo.

            Al utilizar la plataforma EjeCarga, usted reconoce y acepta los términos antes descritos. Es importante que los usuarios revisen y comprendan estos términos antes de utilizar los servicios de la plataforma.
          </p>
          <Link to="/login" className='btn btn-success'>
            Ir a iniciar sesión
          </Link>
        </div>
      </div>
     
    </section>
  );
};

export default TermsConditions;
