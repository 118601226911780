import React, { useState, useEffect } from 'react';
import DataTable from '../../utils/DataTable'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import { BsTrash, BsPencil, BsBank, } from 'react-icons/bs';
import { AiOutlineCheck, } from 'react-icons/ai';
import { GiCancel, } from 'react-icons/gi';

const UsersControlPanel = () => {
  const [users, setUsers] = useState([]);
  const [token] = useState(localStorage.getItem('authToken'));
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    citizenship_card: '',
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    company: '',
    profile_picture: '',
    verified: '',
    roles: 'client',
  });
  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Available funds', accessor: 'available_funds' },
    { Header: 'Citizenship ID', accessor: 'citizenship_card' },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Contact Number', accessor: 'contact_number' },
    { Header: 'Company', accessor: 'company' },
    {
      Header: 'Verified',
      accessor: 'verified',
      Cell: ({ row }) => (
        row.original.verified ? <AiOutlineCheck/> : <GiCancel/>
      ),
    },
    {
      Header: 'Roles',
      accessor: 'roles',
      Cell: ({ row }) => (
        row.original.roles?.map((role, index) => (
          <li key={index}>{role.name}</li>
        )) 
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          <BsPencil 
            className="icon-details mt-3 ms-3 pencil-color" 
            type="button" data-bs-toggle="modal" 
            data-bs-target="#userModal" 
            onClick={() => handleEdit(row.original)}
          />
          <BsTrash className="icon-details mt-3 ms-3 trash-color" type="button" onClick={() => handleDelete(row.original.id)} />       
          <Link to={`/users/funds/${row.original.id}`}>
            <BsBank className="icon-details mt-3 ms-3 trash-color" type="button" />       
          </Link>
        </>
      ),
    },
  ];
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}users/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const users = response.data.users;
      setUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.stringify(formData)
      
      const response = await axios.post(`${API_BASE_URL}users`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers([...users, response.data]);
      setFormData({
        citizenship_card: '',
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        company: '',
        profile_picture: '',
        verified: '',
      });
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleDelete = async (userId) => {
    const shouldDelete = window.confirm('Are you sure you want to remove this user?');

    if (!shouldDelete) {
      return; // Si el usuario cancela, no hacemos nada
    }
    try {
      await axios.delete(`${API_BASE_URL}users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleEdit = (user) => {
    // Set the user data to the formData state
    setFormData({
      citizenship_card: user.citizenship_card,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      contact_number: user.contact_number,
      company: user.company,
      profile_picture: user.profile_picture,
      verified: user.verified,
      roles: user.roles,
    });
  
    // Set the editingUser state to the current user object
    setEditingUser(user);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { id } = editingUser;
      // Make a PUT request to update the user
      await axios.put(`${API_BASE_URL}users/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // Fetch all users again after updating
      fetchUsers();

      // Clear the formData state and editingUser state
      setFormData({
        citizenship_card: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        contact_number: '',
        company: '',
        profile_picture: '',
        verified: '',
        roles: '',
      });
      setEditingUser(null);

    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  useEffect(() => {
    fetchUsers()
  }, []);

  return (
    <div className='container mt-2'>
       <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#userModal">
        Add new user
      </button>
      <DataTable columns={columns} data={users} />
        {/* Modal */}
        <div className="modal fade" id="userModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create New user</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setEditingUser(null)}></button>
            </div>
            <form onSubmit={editingUser ? handleUpdate : handleSubmit}>
              <div className="modal-body row">
                {/* Input fields for creating a new user */}
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="citizenship_card">Citizenship card:</label>
                    <input type="number" className="form-control" name="citizenship_card" value={formData.citizenship_card} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="first_name">first name:</label>
                    <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="last_name">Last name:</label>
                    <input type="text" className="form-control" name="last_name" value={formData.last_name} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="email">email:</label>
                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="company">Company:</label>
                    <input type="text" className="form-control" name="company" value={formData.company} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="password">password:</label>
                    <input type="text" className="form-control" name="password" onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="contact_number">Contact number:</label>
                    <input type="number" className="form-control" name="contact_number" value={formData.contact_number} onChange={handleInputChange} />
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="verified">Verified:</label>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" name="verified" checked={formData.verified} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="verified">Roles:</label>
                    <select className="form-select" name="roles" value={formData?.roles[0]?.name} onChange={handleInputChange}>
                      <option value="client">Client</option>
                      <option value="admin">Admin</option>
                      <option value="driver">Driver</option>
                    </select>
                  </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setEditingUser(null)}>Close</button>
                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">
                  {editingUser ? 'Save changes' : 'Create user'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersControlPanel;
