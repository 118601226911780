import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/components/pages/Freights.scss'; 
import axios from 'axios';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; 
import useCustomFunctions from '../../utils/useCustomFunctions';
import { ToastContainer, toast } from 'react-toastify';

import { 
  BsTrash,
  BsPencil,
  BsClipboard2Check
 } from 'react-icons/bs';


const FreightsRecords = () => {
  const [freights, setFreights] = useState([]);
  const [pending, setPending] = useState([]);
  const [inTransit, setInTransit] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const [date, setDate] = useState('');
  const [token] = useState(localStorage.getItem('authToken'));
  const [authUserId] = useState(localStorage.getItem('userId'));
  const { showFreight } = useCustomFunctions();

  const [editingFreight, setEditingFreight] = useState(null);

  const [formData, setFormData] = useState({
    from: '',
    to: '',
    vehicle_types: 'truck',
    total_weight: '',
    extra_long_charge: false,
    load_service: false,
    download_service: false,
    price: '',
    status: '',
    finished: false,
    user_id: '',
    description: '',
    date_load: '',
    hour_load: '',
    date_download: '',
    hour_download: '',
  });

  const getDate = (specificDate) => {
    if (specificDate) {
      setDate(specificDate);
      fetchFreightSeparateStates(specificDate)
    } else {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
  
      const threshold = 14;
      let defaultDate;
      if (day <= threshold) {
        defaultDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      } else {
        defaultDate = `${year}-${month.toString().padStart(2, '0')}-15`;
      }
      setDate(defaultDate);
      fetchFreightSeparateStates(defaultDate)
    }
  };
  
  
  const fetchFreightSeparateStates = async (newDate) => {
    try {
      const response = await axios.get(`${API_BASE_URL}freights/according-state/${newDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPending(response.data?.pending);
      setInTransit(response.data?.in_transit);
      setDelivered(response.data?.delivered);
      setCancelled(response.data?.cancelled);
      setFreights(response.data?.all);
    } catch (error) {
      console.error('Error fetching freights:', error);
    }
  }; 

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    let inputValue;

    if (name === 'price' && (value.includes('.') || value.includes(','))) {
      // Si el campo es 'price' y contiene puntos o comas, elimina esos caracteres
      inputValue = value.replace(/[.,]/g, '');
    } else {
      inputValue = type === 'checkbox' ? checked : value;
    }
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.stringify(formData)
      
      const response = await axios.post(`${API_BASE_URL}freights`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setFreights([response.data, ...freights]);
      setFormData({
        from: '',
        to: '',
        vehicle_types: '',
        total_weight: '',
        extra_long_charge: '',
        load_service: false,
        download_service: false,
        price: '',
        status: '',
        finished: false,
        user_id: '',
        description: '',
        date_load: '',
        hour_load: '',
        date_download: '',
        hour_download: '',
      });

      getDate()

      toast.success('Felicitaciones, creó una nueva carga.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.error('Error creating freight:', error);
    }
  };

  const handleDelete = async (freightId) => {
    const shouldDelete = window.confirm('¿Estás seguro de que quieres eliminar este flete?');

    if (!shouldDelete) {
      return; // Si el usuario cancela, no hacemos nada
    }
    try {
      await axios.delete(`${API_BASE_URL}freights/${freightId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFreights(freights.filter((freight) => freight.id !== freightId));

      getDate()
      
      toast.success('Fue eliminado exitosamente', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.error('Error deleting freight:', error);
    }
  };

  const handleEdit = (freight) => {
    // Set the freight data to the formData state
    setFormData({
      from: freight.from,
      to: freight.to,
      vehicle_types: freight.vehicle_types,
      total_weight: freight.total_weight,
      extra_long_charge: freight.extra_long_charge,
      load_service: freight.load_service,
      download_service: freight.download_service,
      price: freight.price,
      status: freight.status,
      finished: freight.finished,
      user_id: freight.user_id,
      description: freight.description,
      date_load: freight.date_load,
      hour_load: freight.hour_load,
      date_download: freight.date_download,
      hour_download: freight.hour_download,
    });
  
    // Set the editingFreight state to the current freight object
    setEditingFreight(freight);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { id } = editingFreight;
      // Make a PUT request to update the freight
      await axios.put(`${API_BASE_URL}freights/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // Fetch all freights again after updating
      getDate()

      // Clear the formData state and editingFreight state
      setFormData({
        from: '',
        to: '',
        vehicle_types: '',
        total_weight: '',
        extra_long_charge: false,
        load_service: false,
        download_service: false,
        price: '',
        finished: false,
        user_id: '',
        description: '',
        date_load: '',
        hour_load: '',
        date_download: '',
        hour_download: '',
      });
      setEditingFreight(null);

      toast.success('The update was a success', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.error('Error updating freight:', error);
    }
  };

  const printFreights = (freights) => {
    return (
      freights.map((freight) => (
        <div className='mt-3 freight-records' key={freight.id}>
          <div className="right-align">
            {
              freight.finished ? 
                <BsClipboard2Check className="icon-finished" />
              : 
              <>
                {
                  freight.status === 'pending' || freight.status === 'in transit' ?
                  <BsPencil 
                    className="icon-details mt-1 pencil-color" 
                    type="button" data-bs-toggle="modal" 
                    data-bs-target="#freightModal" 
                    onClick={() => handleEdit(freight)}
                  />
                  : null
                }
              </>
            }
            {
              freight.status === 'pending' ?
              <BsTrash className="icon-details mt-1 trash-color" type="button" onClick={() => handleDelete(freight.id)} />
              : null
            }
          </div>
          <Link to={`/freights/show/${freight.id}`} className="link-content">
            {
              showFreight(freight)
            }
          </Link>
        </div>
      ))
    );
  }

  useEffect(() => {
    // Fetch separates freights from the API when the component mounts
    getDate()
  }, []);

  return (
    <div className='container mt-3'>
      <ToastContainer />
      {/* Button to trigger the modal */}
      <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#freightModal" 
        onClick={() => {
            setEditingFreight(null)
            setFormData({
              from: '',
              to: '',
              vehicle_types: '',
              total_weight: '',
              extra_long_charge: false,
              load_service: false,
              download_service: false,
              price: '',
              status: '',
              finished: false,
              user_id: '',
              description: '',
              date_load: '',
              hour_load: '',
              date_download: '',
              hour_download: '',
            })
          }
        }>
          Agregar nuevo flete
      </button>

      <div className="form-group col-md-3 col-sm-12">
        <h6 className="mt-3" htmlFor="date_load">Traer fletes desde: {date}</h6>
        <input
          type="date"
          className="form-control"
          name="date_load"
          value={date}
          onChange={(e) => getDate(e.target.value)}
        />
      </div>

      <Tabs className="mt-3">
        <TabList>
          <Tab>Pendientes</Tab>
          <Tab>En tránsito</Tab>
          <Tab>Entregados</Tab>
          <Tab>Todos</Tab>
          <Tab>Cancelados</Tab>
        </TabList>

        <TabPanel>
          {printFreights(pending)}
        </TabPanel>
        <TabPanel>
          {printFreights(inTransit)}
        </TabPanel>
        <TabPanel>
          {printFreights(delivered)}
        </TabPanel>
        <TabPanel>
          {printFreights(freights)}
        </TabPanel>
        <TabPanel>
          {printFreights(cancelled)}
        </TabPanel>
      </Tabs>

      <section>
        {/* Modal */}
        <div className="modal fade" id="freightModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{editingFreight ? 'Editar flete' : 'Crear flete'}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setEditingFreight(null)}></button>
              </div>
              {
                !(formData.from && formData.to && formData.total_weight && formData.price) ?
                  <div className="alert alert-info" role="alert">
                    Los campos con * son obligatorios
                  </div>
                : null
              }
              
              <form onSubmit={editingFreight ? handleUpdate : handleSubmit}>
                <div className="modal-body row">
                  {/* Input fields for creating a new freight */}
              
                  {
                    formData.status === 'pending' || formData.status === '' ?
                    <>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="from">Desde: *</label>
                        <input type="text" className="form-control" name="from" value={formData.from} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="to">Hasta: *</label>
                        <input type="text" className="form-control" name="to" value={formData.to} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="vehicle_types">Tipo de Vehículo *:</label>
                        <select
                          className="form-select"
                          name="vehicle_types"
                          value={formData.vehicle_types}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="" disabled>Selecciona un tipo</option>
                          <option value="tractor-trailer">Tracto camión</option>
                          <option value="double-truck">Doble troque</option>
                          <option value="truck">Camión</option>
                          <option value="light-truck">Turbo</option>
                          <option value="van">Furgón</option>
                          <option value="pickup">Camioneta</option>
                          <option value="tow-truck">Grúa</option>
                          <option value="forklift">Montacarga</option>
                        </select>
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="total_weight">Peso total en kilogramos: *</label>
                        <input type="number" className="form-control" name="total_weight" value={formData.total_weight} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="description">Descripción:</label>
                        <textarea className="form-control" name="description" value={formData.description} onChange={handleInputChange} />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="date_load">Fecha de cargue:</label>
                        <input type="date" className="form-control" name="date_load" value={formData.date_load} onChange={handleInputChange} />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="hour_load">Hora de cargue:</label>
                        <input type="time" className="form-control" name="hour_load" value={formData.hour_load} onChange={handleInputChange} />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="date_download">Fecha de descargue:</label>
                        <input type="date" className="form-control" name="date_download" value={formData.date_download} onChange={handleInputChange} />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="hour_download">Hora de descargue:</label>
                        <input type="time" className="form-control" name="hour_download" value={formData.hour_download} onChange={handleInputChange} />
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="extra_long_charge">Carga extralarga:</label>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" name="extra_long_charge" checked={formData.extra_long_charge} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="load_service">Servicio de carga:</label>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" name="load_service" checked={formData.load_service} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="download_service">Servicio de descargue:</label>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" name="download_service" checked={formData.download_service} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="price">Precio: *</label>
                        <input type="number" className="form-control" name="price" value={formData.price} onChange={handleInputChange} required />
                      </div>
                    </> 
                    : null
                  }
                  {
                    editingFreight && formData.status === "in transit"?
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="finished">Finalizado:</label>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" name="finished" checked={formData.finished} onChange={handleInputChange} />
                        </div>
                      </div>
                    : 
                    null
                  }
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setEditingFreight(null)}>Cerrar</button>
                  <button 
                    type="submit" 
                    className="btn btn-success" 
                    data-bs-dismiss="modal"
                    disabled={
                      !(formData.from && formData.to && formData.total_weight && formData.price)
                    } >
                    {editingFreight ? 'Guardar cambios' : 'Crear flete'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreightsRecords;
