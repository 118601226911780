import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL  } from  '../../../utils/apiConfig'; // Adjust the path as needed
import { LiaPiggyBankSolid } from 'react-icons/lia';
import { GiMoneyStack } from 'react-icons/gi';

const TripsMade = () => {
  const [user, setUser] = useState([]);
  const [totalMoneyEarned, setTotalMoneyEarned] = useState('');
  const [token] = useState(localStorage.getItem('authToken'));
  const [authUserId] = useState(parseInt(localStorage.getItem('userId')));

  useEffect(() => {
    fetchTripsMade()
  }, []);

  const fetchTripsMade = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}users/show/${authUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data.user;
      setUser(user);
      const responsetotalMoneyEarned = await axios.get(`${API_BASE_URL}against-freights-offers/total-money-earned/${authUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalMoneyEarned(responsetotalMoneyEarned?.data?.total);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const getBackground = () => {
    let backgroundColor = ''
    user.available_funds < 0 ? 
      backgroundColor = 'danger' : backgroundColor = 'success'
    return backgroundColor;
  }
 

  return (
    <div className='mt-3 trips-made'>
      <h2><LiaPiggyBankSolid/> Presupuesto</h2>
      <h2 className={`btn btn-${getBackground()}`}>${user.available_funds}</h2>
      <h2><GiMoneyStack/> Dinero total ganado </h2>
      <h1 ><GiMoneyStack/> ${totalMoneyEarned}</h1>
    </div>
  );
};

export default TripsMade;
