import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { 
  BsClipboard2Check, 
  BsCalendarCheck,
  BsAlarm,
  BsTruckFlatbed,
 } from 'react-icons/bs';
import { BiRuler } from 'react-icons/bi';
import { MdOutlineIncompleteCircle, MdOutlineDescription, MdForklift, MdLocalShipping } from 'react-icons/md';
import { GoLaw } from 'react-icons/go';
import { GiTowTruck, GiTruck } from 'react-icons/gi';
import useFormatTime from './useFormatTime';
import { 
  FaClock, 
  FaTruck, 
  FaCheckCircle, 
  FaTimesCircle,
  FaRoute, 
  FaPeopleCarry, 
  FaTruckLoading, 
  FaTruckPickup, 
  FaTrailer,
  FaTruckMoving,
} from 'react-icons/fa';
import '../assets/styles/components/pages/Freights.scss'

const useCustomFunctions = () => {
  const { formatTime } = useFormatTime();
 
  const handleAddFreight = (freight) => {
    // Your code to add the new freight
    const container = (
      <Link to={`/freights/show/${freight.id}`} className='Freights'>
        <div className="alert alert-success" role="alert">
          ¡Nuevo flete, haz una oferta ya!
        </div>
        <ul>
          <li>
            {`De ${freight.from} Hasta ${freight.to}`}
          </li>
          <li>
            { freight.price }
          </li>
        </ul>
      </Link>
    )
    // Show a success notification
    toast.success(container, {
      position: 'top-right',
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const showFreight = (freight) => {
    const statusIcon = (status) => {
      switch (status) {
        case 'pending':
          return <>Pendiente <FaClock /></>;
        case 'in transit':
          return <>En tránsito <FaTruck /></>;
        case 'delivered':
          return <>Entregado <FaCheckCircle /></>;
        case 'cancelled':
          return <>Cancelado <FaTimesCircle /></>;
        default:
          return null;
      }
    };
    const vehicleTypes = (type) => {
      switch (type) {
        case 'tractor-trailer':
          return <>Tracto camión <GiTruck className='icon-details' /></>;
        case 'double-truck':
          return <>Doble troque <div><FaTrailer className='icon-details' /><FaTruckMoving className='icon-details' /></div></>;
        case 'truck':
          return <>Camión <BsTruckFlatbed className='icon-details' /></>;
        case 'light-truck':
          return <>Turbo <MdLocalShipping className='icon-details' /></>;
        case 'van':
          return <>Furgón <FaTruck className='icon-details' /></>;
        case 'pickup':
          return <>Camioneta <FaTruckPickup className='icon-details' /></>;
        case 'tow-truck':
          return <>Grúa <GiTowTruck className='icon-details' /></>;
        case 'forklift':
          return <>Montacarga <MdForklift className='icon-details' /></>;
        default:
          return null;
      }
    };
    return (
      <div className='mt-3 freight-records' key={freight.id}>
        <ul className="list-group">
          <li className="list-group-item title d-flex justify-content-between align-items-center">
            {`${freight?.user?.first_name} ${freight?.user?.last_name}`}
            <span className="badge bg-light">{freight?.user?.company}</span>
          </li>
        </ul>

        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <FaRoute/> Ruta
                </div>
                <span className="badge bg-light">{ freight.from + ' - ' + freight.to}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Tipo de Vehículo
                <span className="badge bg-light">{
                  vehicleTypes(freight.vehicle_types)
                }</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <GoLaw/> Peso total
                </div>
                <span className="badge bg-light">{freight.total_weight} kg</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <FaPeopleCarry/> Servicio de carga
                </div>
                <span className="badge bg-light">{freight.load_service ? 'Yes' : 'No'}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <FaTruckLoading/> Servicio de descarga
                </div>
                <span className="badge bg-light">{freight.download_service ? 'Yes' : 'No'}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <BiRuler/> Carga extralarga
                </div>
                <span className="badge bg-light">{freight.extra_long_charge ? 'Yes' : 'No'}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Estado
                <span className="badge bg-light">{
                  statusIcon(freight.status)
                }</span>
              </li>
            </ul>
          </div>
          <div className='col-md-6 col-sm-12'>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <BsCalendarCheck/> Fecha de cargue
                </div>
                <span className="badge bg-light">{freight.date_load}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <BsAlarm/> Hora de cargue
                </div>
                <span className="badge bg-light">{formatTime(freight.hour_load)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <BsCalendarCheck/> Fecha de descargue
                </div>
                <span className="badge bg-light">{freight.date_download}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <BsAlarm/> Hora de descargue
                </div>
                <span className="badge bg-light">{formatTime(freight.hour_download)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <div className='row'>
                  <h6 className='col-12'><MdOutlineDescription/> Descripción</h6>
                  <div className='text-color'>
                    {freight.description}
                  </div>
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                $ Precio
                <span className="badge bg-light">$ {freight?.price?.toLocaleString('es')}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Finalizado
                <span className="badge bg-light">
                  {
                    freight.finished ? 
                      <div className="icon-finished">
                        Si <BsClipboard2Check />
                      </div> 
                    : 
                    <div>
                      No <MdOutlineIncompleteCircle />
                    </div> 
                  }
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return {
    handleAddFreight,
    showFreight,
  };
};

export default useCustomFunctions;
