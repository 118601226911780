import React from 'react';
import '../../../assets/styles/containers/Chats/Chat.scss'; // Adjust the path as needed
import useChat from './useChat'; // Adjust the path to the actual location of useChat.js
import { Link } from 'react-router-dom';
import { BsTelephoneFill } from 'react-icons/bs';

const Chat = () => {
  const {
    messages,
    inputMessage,
    setInputMessage,
    handleSubmit,
    chatWith,
    chatContainerRef,
    STORAGE_BASE_URL
  } = useChat();

  return (
    <section className='container'>
      <div className="chat msger mt-2">
          <header className="msger-header">
            <Link to={`/profile/${chatWith.id}`}>
                <div className="msger-header-title">
                  <div className="profile-picture-chats col-1">
                    <div className="banner ms-3 mt-2">
                        {
                          chatWith.profile_picture ?
                            <img viewBox="0 0 100 100" src={ `${STORAGE_BASE_URL}${chatWith?.profile_picture}` } alt={chatWith?.last_name} />
                          : null
                        }
                    </div>
                  </div>
                  <span className="chat-with col ms-5">
                    { 
                      chatWith.first_name ?
                      `${chatWith.first_name} ${chatWith.last_name}`
                      : null
                    }
                  </span>
                </div>
              </Link>
            <div className="msger-header-options">
              <span className="chatStatus offline">
                <i className="fas fa-globe"></i>
              </span>
              <a className='call' href={`tel:${chatWith.contact_number}`}>
                <BsTelephoneFill /> {chatWith.contact_number}
              </a>
            </div>
          </header>
        <div className="msger-chat" id="chat-container" ref={chatContainerRef}>
          {messages.map((message, index) => (
            <div className={`msg ${message.side}-msg`} key={index}>

              <div className={message.side === 'right' ? "msg-bubble msg-bubble-right" : "msg-bubble msg-bubble-left"}>
                <div className="msg-text">{message.text}</div>
                <div className="msg-info-time">{message.date}</div>
              </div>
            </div>
          ))}
        </div>
        <form className="msger-inputarea" onSubmit={handleSubmit}>
          <input
            type="text"
            className="msger-input"
            onChange={(e) => setInputMessage(e.target.value)}
            value={inputMessage}
            placeholder="Ingrese su mensaje."
          />
          <button type="submit" className="msger-send-btn">
            Entregar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Chat;
