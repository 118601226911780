import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomFunctions from '../../utils/useCustomFunctions';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import '../../assets/styles/components/pages/Freights.scss'; 

// Icons
import { 
  BsTrash,
  BsPencil,
  BsTelephoneFill,
  BsPersonCircle,
 } from 'react-icons/bs';
import { PiChatsBold } from 'react-icons/pi';
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');


const ShowFreight = () => {
  const navigate = useNavigate();
  const [freight, setFreight] = useState([]);
  const [updateOffers, setUpdateOffers] = useState(false);
  const [token] = useState(localStorage.getItem('authToken'));
  const [roles] = useState(localStorage.getItem('roles'));
  const [authUserId] = useState(parseInt(localStorage.getItem('userId')));
  const [authUser, setAuthUser] = useState([]);
  const freightId = useParams().freightId;
  const { showFreight } = useCustomFunctions();

  const [editingOffer, setEditingOffer] = useState(null);

  const [formData, setFormData] = useState({
    price: '',
    freight_id: freightId,
  });

  useEffect(() => {
    fetchFreight();
    fetchAuthUser();
  }, [updateOffers]);

  const fetchFreight = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}freights/show/${freightId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFreight(response.data);
    } catch (error) {
      console.error('Error fetching freight:', error);
    }
  };

  const fetchAuthUser = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}users/show/${authUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAuthUser(response.data?.user);
    } catch (error) {
      console.error('Error fetching freight:', error);
    }
  };

  const fetchChatId = async (driverId) => {
    try {
      const responseChat = await axios.get(`${API_BASE_URL}chats/with/${driverId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
      });

      const chatId = responseChat?.data?.conversation?.id;
      navigate(`/chats/${chatId}`);
    } catch (error) {
      console.error('Error fetching freight:', error);
    }
  }

  const deleteChatId = async (driverId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}chats/by-participants/${authUserId}/${driverId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
      });

      const chatId = response?.data?.id;
      if(chatId) {
        const responseChat = await axios.delete(`${API_BASE_URL}chats/${chatId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        });
      }

    } catch (error) {
      console.error('Error fetching freight:', error);
    }
  }

  const handleAcceptOffer = async (offer) => {
    // Show the confirmation dialog
    const confirmed = window.confirm('¿Estás seguro de que quieres aceptar esta oferta?');
    if (!confirmed) {
      return; // If the user cancels the confirmation, do nothing
    }

    try {
      const response = await axios.put(`${API_BASE_URL}against-freights-offers/${offer.id}`, {
        offers_accepted: true,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(
        await axios.get(`${API_BASE_URL}chats/with/${offer.user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      )
    
      // Update offers
      setUpdateOffers(!updateOffers)

      // Show a success toast
      toast.success('Offer accepted successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Redirect to chat with the driver
      const driverId = response?.data?.user_id;
      fetchChatId(driverId)

      // Update the offer status locally if needed
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error accepting offer:', error);
      // Show an error toast
      toast.error('Error accepting offer. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleCancelContract = async (offer) => {
    // Show the confirmation dialog
    const confirmed = window.confirm('¿Estás seguro de que deseas cancelar este contrato?');
    if (!confirmed) {
      return; // If the user cancels the confirmation, do nothing
    }

    try {
      await axios.put(`${API_BASE_URL}against-freights-offers/${offer.id}`, {
        offers_accepted: false,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(
        await axios.get(`${API_BASE_URL}chats/by-participants/${offer.user_id}/${freight.user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => (
           axios.delete(`${API_BASE_URL}chats/${response.data?.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        ))
      )

      deleteChatId(offer.user_id)

      // Update offers
      setUpdateOffers(!updateOffers)
      // Show a success toast
      toast.success('Offer cancel successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Update the offer status locally if needed
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('Error accepting offer:', error);
      // Show an error toast
      toast.error('Error accepting offer. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const showButtonsAcceptCancelOffer = (against_freights_offer) => {
    if (against_freights_offer.offers_accepted) {
      return (
        <>
          {
             roles !== 'driver' && 
             freight.finished !== true &&
             freight.status === "in transit" 
             ?
             <div>
              <button type="button" className="btn">
                <span className="badge bg-danger" onClick={() => handleCancelContract(against_freights_offer)}>Cancel offer</span>
              </button>
              <span className="badge bg-light">
                <a className='call' href={`tel:${against_freights_offer?.user?.contact_number}`}>
                  <BsTelephoneFill /> {against_freights_offer?.user?.contact_number}
                </a>
                <button type="button" className="ms-4 btn btn-light chat-with" onClick={() => fetchChatId(against_freights_offer?.user_id)}>
                  <PiChatsBold/> Chatear
                </button>
              </span>
             </div>
            : null
          }
        </>
      );
    } else if (authUserId === freight?.user.id) {
      return (
        <button type="button" className="btn" onClick={() => handleAcceptOffer(against_freights_offer)}>
          <span className="badge bg-success">Aceptar la oferta</span>
        </button>
      )
    }
  }

  const showButtonsEditDeleteOffer = (against_freights_offer) => {

    const offersAccepted = !!(against_freights_offer.offers_accepted);

    if (offersAccepted === false && authUserId === against_freights_offer.user_id) {
      return (
        <div>
          <button type="button" className="btn" onClick={() => handleEdit(against_freights_offer)}>
            <span className="badge bg-success" data-bs-toggle="modal" data-bs-target="#freightModal"><BsPencil/> Editar oferta</span>
          </button>
          <button type="button" className="btn" onClick={() => handleDelete(against_freights_offer.id)}>
            <span className="badge bg-danger"><BsTrash/> Eliminar oferta</span>
          </button>
        </div>
      )
    }
  }

  const showButtonCreateOffer = () => {
    const foundOffer = freight?.against_freights_offers?.find((offer) => offer.user_id === authUserId);
    if (foundOffer || roles === 'client') {
      return null;
    } else if (authUser.verified) {
      return (
        <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#freightModal">
          Añadir nueva oferta
        </button>
      );
    } else {
      return (
        <div className="alert alert-warning" role="alert">
          Llama al <a className='call' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a> para ser un conductor verificado y acceder a los fletes ofertados
        </div>
      );
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue;

    if (name === 'price' && (value.includes('.') || value.includes(','))) {
      // Si el campo es 'price' y contiene puntos o comas, elimina esos caracteres
      inputValue = value.replace(/[.,]/g, '');
    } else {
      inputValue = type === 'checkbox' ? checked : value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const minimumBidAllowed = (freight.price) * 0.9;
     // Check if the offered price is equal to or greater than freight price
    if (formData.price < minimumBidAllowed) {
      toast.error(`El precio ofertado debe ser igual o mayor que ${minimumBidAllowed} para poder hacer una oferta sobre este flete.`, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Do not proceed with the API call
    } else {
      // Validate user funds
      try {
        const response = await axios.get(`${API_BASE_URL}users/show/${authUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const availableFunds = response.data?.user?.available_funds
        const minimumFunds = (formData.price) * 0.05;
  
        if(availableFunds < minimumFunds) {
          toast.error(`Debes tener $${minimumFunds} en tu cuenta para poder hacer una oferta sobre este flete, puedes llamar al 319 681 4584 para recargar tu cuenta`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    try {
      const data = JSON.stringify(formData)
      
      const response = await axios.post(`${API_BASE_URL}against-freights-offers`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      fetchFreight()
      setFormData({
        price: '',
        freight_id: freightId,
      });
    } catch (error) {
      console.error('Error creating freight:', error);
    }
  };

  const handleDelete = async (id) => {
    const shouldDelete = window.confirm('¿Estás seguro de que deseas eliminar esta oferta?');

    if (!shouldDelete) {
      return; // Si el usuario cancela, no hacemos nada
    }
    try {
      await axios.delete(`${API_BASE_URL}against-freights-offers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchFreight()
    } catch (error) {
      console.error('Error deleting freight:', error);
    }
  };

  const handleEdit = (offer) => {
    // Set the offer data to the formData state
    setFormData({
      price: offer.price,
      type: 'change price',
    });
  
    // Set the editingOffer state to the current freight object
    setEditingOffer(offer);
  };
  
  const handleUpdate = async (e) => {
    e.preventDefault();

    const minimumBidAllowed = (freight.price) * 0.9;
     // Check if the offered price is equal to or greater than freight price
    if (formData.price < minimumBidAllowed) {
      toast.error(`Offered price must be equal to or greater than ${minimumBidAllowed} to be able to make an offer on this freight.`, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Do not proceed with the API call
    } else {
      // Validate user funds
      try {
        const response = await axios.get(`${API_BASE_URL}users/show/${authUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const availableFunds = response.data?.user?.available_funds
        const minimumFunds = (formData.price) * 0.05;
  
        if(availableFunds < minimumFunds) {
          toast.error(`Debes tener $${minimumFunds} para poder hacer una oferta sobre este flete, puedes llamar al 319 681 4584 para recargar tu cuenta`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    try {
      const { id } = editingOffer;

      // Make a PUT request to update the freight
      await axios.put(`${API_BASE_URL}against-freights-offers/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // Fetch all freights again after updating
      fetchFreight(); 

      // Clear the formData state and editingOffer state
      setFormData({
        price: '',
        freight_id: freightId,
      });
      setEditingOffer(null);

    } catch (error) {
      console.error('Error updating freight:', error);
    }
  };

  const alertNotification = () => {
    toast.success('Mira las ofertas', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  const showAgainstFreightsOffer = (against_freights_offer) => {
    const minimumFunds = freight.price * 0.05;
    const fundsAvailable = against_freights_offer?.user?.available_funds;

    let showOffersAccepted = (against_freights_offer.offers_accepted || against_freights_offer.user_id === authUserId)
    showOffersAccepted = (showOffersAccepted && roles === "client")

    if (minimumFunds <= fundsAvailable || showOffersAccepted) {
      
      return (
        <ol className="list-group mt-3" key={against_freights_offer.id}>
          <li className="mt-3 list-group-item d-flex justify-content-between align-items-start">
            <div className="me-auto row">
              <div className='profile-picture col-1'>
                <Link to={`/profile/${against_freights_offer.user_id}`} className="banner mt-2 ms-3">
                  {
                    !!(against_freights_offer?.user?.profile_picture) ? 
                    <img viewBox="0 0 100 100" src={ `${STORAGE_BASE_URL}${against_freights_offer?.user?.profile_picture}` } alt={against_freights_offer?.user?.last_name} />
                    : 
                    <div><BsPersonCircle style={{ fontSize: '2rem' }}/></div>
                  }
                </Link>
              </div>
              <div className="fw-bold col-6 ms-4">
                {
                  `${against_freights_offer?.user?.first_name} 
                  ${against_freights_offer?.user?.last_name}
                  ${against_freights_offer?.user?.company ? against_freights_offer?.user?.company : ''}`
                }
              </div>
              <div className='ms-5'>
                $ {against_freights_offer?.price?.toLocaleString('es')}
              </div>
            </div>
            {
              showButtonsAcceptCancelOffer(against_freights_offer)
            }
            {
              showButtonsEditDeleteOffer(against_freights_offer)
            }
          </li>
        </ol>
      );
    } else if (minimumFunds >= fundsAvailable && against_freights_offer.user_id === authUserId) {
      return (
        <div className="alert alert-warning" role="alert">
          Llama a <a className='call' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a>
          para recargar tu cuenta para poder hacer ofertas en los fletes
        </div>
      )
    }
    return;
  }

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '0753623e3427622a8bf9',
      cluster: 'us2', 
      forceTLS: true,
      // auth: {
      //   headers: {
      //     Authorization: `Bearer ${token}`, 
      //   },
      // },
    });

    echo.channel(`offer-channel.${freightId}`)
    .listen('AgainstFreightsOfferEvent', (response) => {
      const userId = response?.offer?.freight.user_id;
      if(authUserId === userId) {
        alertNotification()
      }
      fetchFreight();
    });

    return () => {
      echo.disconnect();
      setTimeout(() => {
      }, 500);
    };
  }, [freightId]);

  return (
    <div className='container mt-3'>
      <ToastContainer />
      {
        showFreight(freight)
      }
      <div className='mt-3'>
        {
          showButtonCreateOffer()
        }
        <h1>Ofertas realizadas</h1>
        {
          freight.status !== 'cancelled' ?
          freight?.against_freights_offers?.map((against_freights_offer) => (
            showAgainstFreightsOffer(against_freights_offer)
          ))
          : null
        }
      </div>
      {/* Modal */}
      <div className="modal fade" id="freightModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Hacer oferta</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setEditingOffer(null)}></button>
            </div>
            <form onSubmit={editingOffer ? handleUpdate : handleSubmit}>
              <div className="modal-body row">
                {/* Input fields for creating a new freight */}
                <ul className="list-group">
                  <li className="list-group-item list-group-item-warning">
                    La oferta debe ser igual o superior al 90% del valor del flete.                  
                  </li>
                  <li className="list-group-item list-group-item-warning">
                    Debes tener el 5% de lo que vas a ofrecer para poder realizar la oferta, puedes llamar al <a className='call' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a> para recargar tu cuenta
                  </li>
                </ul>
                <div className="form-group col-md-6 col-sm-12">
                  <label htmlFor="price">Precio: </label>
                  <input type="number" className="form-control" name="price" value={formData.price} onChange={handleInputChange} />
                </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setEditingOffer(null)}>Cerrar</button>
                <button 
                  type="submit" 
                  className="btn btn-success" 
                  data-bs-dismiss="modal"
                  disabled={
                    !(formData.price)
                  } >
                  {editingOffer ? 'Actualizar oferta' : 'Hacer oferta'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowFreight;
