import React, { useState, useEffect } from 'react';
import '../../assets/styles/components/pages/Profile/Profile.scss';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from  '../../utils/apiConfig'; // Adjust the path as needed
import { useParams } from 'react-router-dom';
import Raiting from '../../components/pages/Profile/Ratings';
import { BsPencil } from 'react-icons/bs';

const Profile = () => {
  const userId = useParams().userId;
  const [token] = useState(localStorage.getItem('authToken'));
  const [user, setUser] = useState([]);
  const [authUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}users/show/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data?.user);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const updateProfile = async () => {
    try {
      const form = document.getElementById('editProfileForm');
      const formData = new FormData(form);

      await axios.post(`${API_BASE_URL}users/update-profile/${userId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If the update was successful, close the modal and update the user data
      fetchUser();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  
  
  return (
    <div className='profile'>
      <div className="card">
        <div className="banner">
          {
            user.profile_picture ?
              <img viewBox="0 0 100 100" src={ `${STORAGE_BASE_URL}${user.profile_picture}` } alt={user.last_name} />
            : null
          }
        </div>
        <div className="menu">
          <div className="opener">     
          {
            authUserId === userId ?
            <BsPencil 
              className="icon-details mt-1 pencil-color" 
              type="button" data-bs-toggle="modal" 
              data-bs-target="#exampleModal"
            />
            : null
          }
          </div>
        </div>
        <h2 className="name">{`${user.first_name} ${user.last_name}`}</h2>
        <div className="title">{ user.company }</div>
        <div className="actions">
          {/* <div className="follow-info row">
            <h2><a><Raiting /></a></h2>
            <h2><a><span>1000</span><small>completed trips</small></a></h2>
          </div> */}
        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Editar Perfil</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="editProfileForm">
                <div className="mb-3">
                  <label htmlFor="company" className="form-label">Nombre de la empresa</label>
                  <input type="text" className="form-control" id="company" name="company" />
                </div>
                <div className="mb-3">
                  <label htmlFor="profilePicture" className="form-label">Foto de Perfil</label>
                  <input type="file" className="form-control" id="profilePicture" name="profile_picture" />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal"  onClick={updateProfile}>Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
