// components/pages/Home.js
import React from 'react';
import businessCardOne from '../../assets/multimedia/businessCardOne.jpeg'; 

const Home = () => {
  return (
    <section className="">
      <img src={businessCardOne} className="d-block w-100" alt="..."/>
    </section>
  );
};

export default Home;
