import React, { useState } from 'react';
import Transactions from '../../components/pages/MyWallet/Transactions'
import TripsMade from '../../components/pages/MyWallet/TripsMade'
import FreightsFinished from '../../components/pages/MyWallet/FreightsFinished'
import Budget from '../../components/pages/MyWallet/Budget'
import { BsTelephoneFill } from 'react-icons/bs';

const Wallet = () => {
  const [roles] = useState(localStorage.getItem('roles'));
 
  return (
    <div className='container mt-2 text-center'>
      <div className="row justify-content-md-center">
          {
            roles !== 'client' ?
            <> 
              <div className="alert alert-primary" role="alert">
                Llama a <a className='call' href={`tel:3196814584`}><BsTelephoneFill /> 319 681 4584 </a>
                para recargar tu cuenta para poder hacer ofertas en los fletes
              </div>
              <div className="col-md-4 col-sm-12">
                <Budget />
              </div>
              <div className="col-md-4 col-sm-12">
                <Transactions />
              </div>
              <div className="col-md-4 col-sm-12">
                <TripsMade />
              </div>
            </>
            : 
            <>
              <div className='col-12'>
                <FreightsFinished />
              </div>
            </>
          }
      </div>
    </div>
  );
};

export default Wallet;
