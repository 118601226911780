import React, { useState, useEffect } from 'react';
import DataTable from '../../utils/DataTable'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import { BsTrash, BsPencil, BsBank, } from 'react-icons/bs';
import { AiOutlineCheck, } from 'react-icons/ai';
import { GiCancel, } from 'react-icons/gi';
import { GiMoneyStack } from 'react-icons/gi';

const Wallet = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalMoneyEarned, setTotalMoneyEarned] = useState('');
  const [token] = useState(localStorage.getItem('authToken'));
  const userId = useParams().userId;
  const [formData, setFormData] = useState({
    amount: '',
    user_id: userId,
  });
  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Amount', accessor: 'amount' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          <BsTrash className="icon-details mt-3 ms-3 trash-color" type="button" onClick={() => handleDelete(row.original.id)} />       
        </>
      ),
    },
  ];
  
  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}transactions/show/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const transactions = response.data.transactions;
      setTransactions(transactions);

      const responsetotalMoneyEarned = await axios.get(`${API_BASE_URL}against-freights-offers/total-money-earned/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalMoneyEarned(responsetotalMoneyEarned?.data?.total);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.stringify(formData)
      
      const response = await axios.post(`${API_BASE_URL}transactions`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setTransactions([...transactions, response.data.transaction]);
      setFormData({
        amount: '',
        user_id: userId,
      });
    } catch (error) {
      console.error('Error creating transaction:', error);
    }
  };

  const handleDelete = async (userId) => {
    const shouldDelete = window.confirm('Are you sure you want to remove this transaction?');

    if (!shouldDelete) {
      return; // Si el usuario cancela, no hacemos nada
    }
    try {
      await axios.delete(`${API_BASE_URL}transactions/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTransactions(transactions.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  useEffect(() => {
    fetchTransactions()
  }, []);

  return (
    <div className='container mt-2'>
       <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#transactionModal">
        Add new transaction
      </button>
      <h2><GiMoneyStack/> Total money earned ${totalMoneyEarned} </h2>
      <DataTable columns={columns} data={transactions} />
        {/* Modal */}
        <div className="modal fade" id="transactionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create New transaction</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body row">
                  <div className="form-group col-md-6 col-sm-12">
                    <label htmlFor="amount">How much money are you going to deposit or withdraw?:</label>
                    <input type="number" className="form-control" name="amount" value={formData.amount} onChange={handleInputChange} />
                  </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-success" data-bs-dismiss="modal">
                  Create transaction
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
