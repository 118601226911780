// useChat.js

import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, STORAGE_BASE_URL  } from  '../../../utils/apiConfig'; // Adjust the path as needed
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

function formatDate(date) {
  const d = date.getDate();
  const mo = date.getMonth() + 1;
  const y = date.getFullYear();
  const h = '0' + date.getHours();
  const m = '0' + date.getMinutes();

  return `${d}/${mo}/${y} ${h.slice(-2)}:${m.slice(-2)}`;
}

const useChat = () => {
  const chatId = useParams();
  const [token] = useState(localStorage.getItem('authToken'));
  const [authUser] = useState(localStorage.getItem('userId'));
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [chatWith, setChatWith] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputMessage) return;

    try {
      let message = JSON.stringify({
        "conversation_id": chatId.userId,
        "message_content": inputMessage
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_BASE_URL}messages/sent`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data: message
      };

      axios.request(config)
        .then((response) => {
          const data = response.data;
          const user = data.sender;
          const newMessage = {
            side: 'right',
            text: data.message_content,
            date: formatDate(new Date(data.created_at)),
          };

          setMessages((prevMessages) => [...prevMessages, newMessage]);
          setInputMessage('');
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '0753623e3427622a8bf9',
      cluster: 'us2', 
      forceTLS: true,
      // auth: {
      //   headers: {
      //     Authorization: `Bearer ${token}`, 
      //   },
      // },
    });

    echo.channel(`chat-channel.${chatId.userId}`)
    .listen('MessageSent', (response) => {
      let message = response.message;
      let side = 'left';
      side = (message.sender_id == authUser) ? 'right' : 'left';
      if (side === 'left') {
        const newMessage = {
          side: side,
          text: message.message_content,
          date: formatDate(new Date(message.created_at)),
        };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    });

    const chatWith = (participants) => {
     let participant = participants.participant1.id != authUser ? 
          participants.participant1 
        : participants.participant2;

      return participant;
    }

    const fetchChatParticipants = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}chats/${chatId.userId}/participants`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setChatWith(chatWith(response.data))
      } catch (error) {
        console.error('Error getting participants:', error);
      }
    };

    const appenedMessages = (messages) => {
      let side = 'left';
      messages?.conversation.forEach(message => {
        side = (message.sender_id == authUser) ? 'right' : 'left';

        const newMessage = {
          side: side,
          text: message.message_content,
          date: formatDate(new Date(message.created_at)),
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
      })
    }

    const fetchGetMessages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}messages/${chatId.userId}/get-messages`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
          setMessages([])
          appenedMessages(response.data)
      } catch (error) {
        console.error('Error getting participants:', error);
      }
    };

    fetchGetMessages();
    fetchChatParticipants();

    return () => {
      setTimeout(() => {
        echo.disconnect();
      }, 500);
    };
  }, [chatId.userId]);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function scrollToBottom() {
    const container = chatContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }

  return {
    messages,
    inputMessage,
    setInputMessage,
    handleSubmit,
    chatWith,
    chatContainerRef,
    scrollToBottom,
    STORAGE_BASE_URL
  };
};

export default useChat;
