import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomFunctions from '../../utils/useCustomFunctions';


import { Link } from 'react-router-dom';
import '../../assets/styles/components/pages/Freights.scss'; 
import axios from 'axios';
import { API_BASE_URL  } from  '../../utils/apiConfig'; // Adjust the path as needed
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');


const FreightsRecords = () => {
  const [freights, setFreights] = useState([]);
  const [token] = useState(localStorage.getItem('authToken'));
  const { showFreight } = useCustomFunctions();
  
  useEffect(() => {
    fetchFreights();
  }, []);

  const fetchFreights = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}freights`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFreights(response.data);
    } catch (error) {
      console.error('Error fetching freights:', error);
    }
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '0753623e3427622a8bf9',
      cluster: 'us2', 
      forceTLS: true,
      // auth: {
      //   headers: {
      //     Authorization: `Bearer ${token}`, 
      //   },
      // },
    });

    echo.channel('freight-channel')
    .listen('FreightEvent', (response) => {
        setFreights((prevFreights) => [response.freight, ...prevFreights]);
    });

    return () => {
      setTimeout(() => {
        echo.disconnect();
      }, 500);
    };
  }, []);

  return (
    <div className='container mt-3 freight-records'>
    <ToastContainer />
    { 
      freights ?
        freights.map((freight) => (
          <Link to={`/freights/show/${freight.id}`} key={freight.id} className="link-content">
            {
              showFreight(freight)
            }
          </Link>
        ))
      : <div className="alert alert-warning" role="alert">
          Llama al <a className='call' href={`tel:3196814584`}> 319 681 4584 </a>
          para ser un conductor verificado para acceder a fletes ofertados
        </div>
      }
    <section>
    <div>
  </div>
    </section>
  </div>
  );
};

export default FreightsRecords;
