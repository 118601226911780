import React from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet'; // Importa la librería react-helmet
import '../../assets/styles/components/pages/PublicHome.scss'; 
import businessCardFour from '../../assets/multimedia/businessCardFour.jpeg'; 
import LogoOne from '../../assets/multimedia/LogoOne.jpeg'; 
import LogoSecond from '../../assets/multimedia/LogoSecond.jpeg'; 
import LogoThird from '../../assets/multimedia/LogoThird.jpeg'; 
import footerContacUs from '../../assets/multimedia/footerContacUs.png'; 
import { BsTelephoneFill } from 'react-icons/bs';

const PublicHome = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className='public-home'>
      <Helmet>
        <title>Ejecarga - Plataforma de Carga Logística</title>
        <meta name="description" content="Ejecarga es tu plataforma de carga logística confiable. Ofrecemos transporte de carga pesada y asesoría legal para tus necesidades comerciales." />
        <meta name="keywords" content="EjeCarga, plataforma, carga logística, transporte, asesoría legal" />
        <meta name="robots" content="index, follow" />
        <link rel="icon" type="image/x-icon" href="../../assets/multimedia/ejecarga.ico" />
      </Helmet>
      {/* <button type="button" className="btn auth mt-1 ms-1" onClick={handleLoginClick}>Iniciar sesión</button>
      <button type="button" className="btn auth mt-1 ms-1" onClick={handleLoginClick}>Registrarme</button> */}
      <section className="">
        <img src={businessCardFour} className="d-block w-100" alt="ejecarga"/>
      </section>
      <section className="mt-5">
        <div className="container text-center">
          <div className="row justify-content-md-center">
            {/* <div className="col-md-auto">
              <div className="card depth mt-3">
                <img src={LogoThird} className="card-img-top" alt="Ejecarga: Tu Plataforma de Transporte de Carga Pesada"/>
                <div className="card-body">
                  <h5 className="card-title">Ejecarga: Tu Plataforma de Transporte de Carga Pesada</h5>
                  <p className="card-text">
                    Descubre la forma más eficiente de transportar materiales de carga pesada. Con Ejecarga, los clientes publican fletes, los conductores ofertan, ¡y todos ganan!<br/>
                    Gestiona tus fletes y ganancias con facilidad en una sola plataforma<br/>
                  </p>
                  <p className="card-text">
                    Ejecarga: fácil, rápido y seguro.
                  </p>
                  <button type="button" className="btn auth mt-1 col-12" onClick={handleLoginClick}>¡Regístrate ya!</button>
                  <p></p>
                  <a className='call ms-1' href={'tel:3196814584'}>
                    <BsTelephoneFill className="icons-details" /> 319 681 4584
                  </a>
                </div>
              </div>
            </div> */}
            <div className="col-md-auto">
              <div className="card depth mt-3">
                <img src={LogoOne} className="card-img-top" alt="Servicio de Transporte"/>
                <div className="card-body">
                  <h5 className="card-title">Servicio de Transporte Ejecarga: Confianza en Movimiento con Conductores de Élite</h5>
                  <p className="card-text">
                    Experimenta la confiabilidad del transporte con Ejecarga.<br/> 
                    <strong>Nuestros camiones y conductores expertos, parte integral de Ejecarga,</strong> aseguran la entrega segura y puntual de tus materiales de carga pesada. ¡Confía en nosotros para llevar tus proyectos al siguiente nivel!
                  </p>
                  <p className="card-text">
                    ¡Llama para cotizar un flete directamente con nosotros!
                  </p>
                  <a className='call' href={'tel:3147604353'}>
                    <BsTelephoneFill className="icons-details" /> 314 760 4353
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="card depth mt-3">
                <img src={LogoSecond} className="card-img-top" alt="Asesoría Legal"/>
                <div className="card-body">
                  <h5 className="card-title">Asesoría Legal Ejecarga</h5>
                  <p className="card-text">
                    Nuestros abogados expertos están aquí para brindarte asesoramiento legal en todas tus necesidades comerciales relacionadas con el transporte de carga pesada. Obtén orientación confiable y protege tus operaciones con Ejecarga.
                  </p>
                  <a className='call' href={'tel:3146889980'}>
                    <BsTelephoneFill className="icons-details" /> 314 688 9980
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container contact-us mt-5">
        <div className="row">
          <div className="col-9 mt-5">
            <p className="col ms-5">
              <strong>EJECARGA</strong><br/>
              <small>EXPERTOS EN TRANSPORTE</small><br/>
            </p>
          </div>
          <div className="col-md-2 col-sm-8">
            <img src={footerContacUs} className="d-block w-100" alt="ejecarga"/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PublicHome;
